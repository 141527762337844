*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  body[data-theme='dark'] {
    --bg_color1: #1d2939;
    --bg_color2: #213445;
    --bg_color3: #1e2b3b;
    --bg_color4: #1e2b3b;
    --bg_color5: #213445;
    --bg_color6: #213445;
    --bg_color7: #41536b;
    --bg_color8: #fff;
    --bg_color9: #304357;
    --text_color1: #fff;
    --text_color2: #dadada;
    --text_color3: #f1f1f1;
    --text_color4: #bcbfc4;
    --text_color5: #717171;
    --text_color6: #037e8c;
    --text_color7: #d1d4d6;
    --box-shadow1: rgba(158, 158, 158, 0.1);
    --svg_color1: #d9dce1;
    --hide_border: 'none';
  }

  body[data-theme='light'] {
    --bg_color1: #fff;
    --bg_color2: #f8f8f8;
    --bg_color3: #e8e9ea;
    --bg_color4: #3b4144;
    --bg_color5: #fff;
    --bg_color6: #3b4144;
    --bg_color7: #fff;
    --bg_color8: transparent;
    --bg_color9: #e8e9ea;
    --text_color1: #3b4144;
    --text_color2: #717171;
    --text_color3: #1a1a1a;
    --text_color4: #3f3f3f;
    --text_color5: #bcc0c5;
    --text_color6: #fff;
    --text_color7: #717171;
    --box-shadow1: rgba(0, 0, 0, 0.1);
    --svg_color1: #3b4144;
    --hide_border: #cdd1d4;
  }
}

body[data-theme='dark'] {
  div[class*='utilityBlock'] img {
    filter: invert(1);
  }
}

img {
  object-fit: cover;
}

body {
  width: 100%;
  height: auto;
  min-height: 100svh;
  overflow-x: hidden;
  background: var(--bg_color1);
}

* {
  &::-webkit-scrollbar {
    background: transparent;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8b8b8b;
    border-radius: 8px;
  }

  form,
  ul {
    &::-webkit-scrollbar {
      width: 6px;
    }
  }
}

#__next>div {
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  outline: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
b,
a,
li,
button,
textarea,
input {
  font-weight: 400;
  color: var(--text_color1);
}

header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddddddb3;
  width: 100vw;
  height: 58px;
  z-index: 99;
  background: var(--bg_color5);

  >div {
    display: flex;
    align-items: center;
    padding-inline: 16px;
    width: 100%;
    margin: 0 auto;
  }

  >a:first-of-type {
    width: 125px;
    height: 31px;

    img {
      width: auto;
      height: auto;
    }
  }

  @media (max-width: 1100px) {
    >div>nav {
      display: none !important;
    }
  }

  @media (max-width: 480px) {
    >a:first-of-type {
      width: 100px;
    }
  }
}

footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.showBtn {
  width: max-content;
  padding-inline: 20px;
  height: 36px;
  background: #037e8c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 30px auto 25px;
  cursor: pointer;
  transition: 0.1s ease-in-out;

  &:hover {
    background: var(--text_color1);
    color: var(--text_color6);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
  }
}

#homeSlider:hover div[class*='seeMore'] {
  display: flex;
}

picture.skeleton {
  background: linear-gradient(-60deg, #e8e8e8 33.33%, #cccccccb, #e8e8e8 66.66%) right/400% 100%;
  animation: skeleton_anim 1.5s linear infinite;
}

picture {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

@keyframes skeleton_anim {
  to {
    background-position: left;
  }
}

.errorPage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 100px;
  padding-inline: 15px;

  h1 {
    font-size: 72px;
    font-weight: 700;
    color: var(--text_color1);
    line-height: 90px;
  }

  h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    margin-bottom: 24px;
    color: var(--text_color1);
  }

  p {
    font-size: 24px;
    line-height: 32px;
    color: var(--text_color1);
  }

  button {
    width: 230px;
    height: 62px;
    margin-top: 55px;
    background: #f63d68;
    border-radius: 8px;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 16px;
    }
  }

  img {
    width: 47%;
    height: 40vw;
    max-width: 700px;
    max-height: 600px;
  }

  ymaps {
    pointer-events: none !important;
  }

  @media (max-width: 1024px) {
    >div {
      width: 100%;
    }

    img {
      display: none;
    }
  }

  @media (max-width: 768px) {
    button {
      width: 100%;
    }
  }
}